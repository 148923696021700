import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "Saved": "Saved",
            "About app": "About app",
            "Good technique": "Good technique",
            "Unsafe technique": "Unsafe technique",
            "Technique": "Technique",
            "Note": "Note",
            "Step": "Step {{idx}}",
            "Development": "Development",
            "Design": "Design",
            "Texts": "Texts",
            "Version": "Version 1.0",
            "Version Date": "2nd August 2024 year",
            "Delete": "Delete",
            "Save": "Save",
            "There are no such exercises yet": "There are no such exercises yet",
            "Write about not found exercises": "Write to us and we will add, although we may not add. In short, Lera will write the text here",
            "Feedback": "Feedback",
            "What muscles do we train": "What muscles do we train",
            "Reload": "Reload",
            "Something went wrong": "Something went wrong",
            "Error content": "Here Lera writes a text to help users understand what to do to correct the situation",
            "Search": "Search",
            "Feedback description": "Leave an exercise, a wish, praise or scold",
            "Feedback textarea placeholder": "I don't like that it's expensive",
            "Feedback sucessfully sent": "Your feedback successfully sent! Thank you.",
            "Feedback limit per day": "You have limits to send feedback - 5 per day!",
            "SubscribedTitle": "Thank you for something. Lera will write the text!",
            "SubscribedText": "And something else funny and cool",
            "ToExercises": "To exercises",
            "SubscribeTitle": "Text motivating to buy or subscribe",
            "SubscribeAdvantageOne": "First advantage",
            "SubscribeAdvantageTwo": "Second advantage",
            "SubscribeAdvantageThree": "Third advantage",
            "SubscribeDescriptionFirstPart": "Monthly subscription, we charge",
            "SubscribeDescriptionSecondPart": "per month until you cancel",
            "SubscribeAction": "Switch to plus",
            "MainPageSubscriptionDescription": "Monthly subscription, cheating every day",
            "MainPageFor": "for"
        }
    },
    ru: {
        translation: {
            "Saved": "Сохраненное",
            "About app": "О приложении",
            "Good technique": "Хорошая техника",
            "Unsafe technique": "Небезопасная техника",
            "Technique": "Техника",
            "Note": "На заметку",
            "Step": "Шаг {{idx}}",
            "Development": "Разработка",
            "Design": "Дизайн",
            "Texts": "Тексты",
            "Version": "Версия 1.0",
            "Version Date": "от 2 августа 2024 года",
            "Delete": "Удалить",
            "Save": "Сохранить",
            "There are no such exercises yet": "Таких упражнений пока нет",
            "Write about not found exercises": "Напишите нам и мы добавим, хотя может и не добавим. Короче лера текст напишет сюда",
            "Feedback": "Обратная связь",
            "What muscles do we train": "Какие мышцы тренируем",
            "Reload": "Перезагрузить",
            "Something went wrong": "Что-то пошло не так",
            "Error content": "Тут лера пишет текст помогающий юзерам понять что сделать, чтобы исправить ситуацию",
            "Search": "Поиск",
            "Feedback description": "Оставьте упражнение, пожелание, похвалите или поругайте",
            "Feedback textarea placeholder": "Мне не нравится, что дорого",
            "Feedback sucessfully sent": "Обратная связь успешно отправлена!",
            "Feedback limit per day": "К сожалению, нельзя оставлять обратную связть более 5 раз в день",
            "SubscribedTitle": "Спасибо за что-то. Лера напишет текст!",
            "SubscribedText": "И что-то ещё забавное и крутое",
            "ToExercises": "К упражнениям",
            "SubscribeTitle": "Текст мотивирующий купить или подписаться",
            "SubscribeAdvantageOne": "Приемущество раз",
            "SubscribeAdvantageTwo": "Приемущество два",
            "SubscribeAdvantageThree": "Приемущество три",
            "SubscribeDescriptionFirstPart": "Месячная подписка, списываем",
            "SubscribeDescriptionSecondPart": "в месяц пока не отмените",
            "SubscribeAction": "Перейти на плюс",
            "MainPageSubscriptionDescription": "Месячная подписка, списывание каждый день",
            "MainPageFor": "за"
        }
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;