import React from "react";
import {BOT_USERNAME} from '../../const.ts';


import SpotyLogo from '../../assets/spoty-logo-2.svg';
import MiniAppScreen from '../../assets/miniappscreen.png';

import './style.css';

const BrowserLaunch = () => {
    return (
        <div className="browser-launch-page">
            <div className="container">
                <img className="logo" src={SpotyLogo} />
                <h1>Мини приложение для тренировок</h1>
                <p>Собираем и редактируем упражнения, чтобы было легче вспомнинать что делать в зале</p>
                <img src={MiniAppScreen} className="app-screen" />
                <a href={`https://t.me/${BOT_USERNAME}?startapp`}>Открыть в телеграме</a>
            </div>
        </div>
    );
};


export default BrowserLaunch;