import React from "react";
import TgStar from '../../assets/tg-star.svg';
import { Link } from "react-router-dom";
import { AppRoute } from "../../const.ts";
import './style.css';
import { useAppSelector } from "../../hooks/index.ts";
import SpotyPlusMini from '../../assets/spoty-plus-mini.svg';
import { useTranslation } from "react-i18next";

const Subscription = () => {
    const { user } = useAppSelector((state) => state);
    const hasSubscription = !!user?.subscription;
    const { t, i18n } = useTranslation();

    return (
        <div className={"subscription" + (hasSubscription ? ' enabled' : '')}>
            <Link to={AppRoute.Subscription} className="subscription-link">
                <div className="subscription-logo">
                    <img src={SpotyPlusMini} alt="Spoty+ subscription" />
                </div>
                {!hasSubscription && <p>{t("MainPageFor")}</p>}
                {!hasSubscription &&
                    <div className="stars-price">
                        <img src={TgStar} />
                        <span>250</span>
                    </div>
                }
            </Link>
            {!hasSubscription && <p className="subscription-descr">{t("MainPageSubscriptionDescription")}</p>}
        </div>
    );
}

export default Subscription;